import "./App.css";
import ReactLogo from './assets/bnollarsvg.svg';
import ReactScreen from './assets/croped.svg';

function App() {
  return (
    <div className='d'>
      <div className='wrapper'>
        <div className='text-area'>
          <img src={ReactLogo} alt="Bnollar Logo" />
          <h1>Coming Soon</h1>
          <p>
            The first social network built on the blockchain with its own NFT
            marketplace.
          </p>
        </div>
        <div className='sircles-img'>
        <img src={ReactScreen} alt="React Logo" />
        </div>
      </div>
    </div>
  );
}

export default App;
